<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addProduct'})">عمليات السحب</dashboard-page-title>

    <main-table :fields="fields"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      fields: [
        { label: '#', key: '#', class: 'text-left', sortable: true },
        { label: 'Id', key: 'lead.id', class: 'text-left', sortable: true },
        { label: 'Name', key: 'lead.name', class: 'text-left', sortable: true },
        /*  { label: 'Phone', key: 'phone', class: 'text-left', sortable: true }, */
        { label: 'Ad Source', key: 'source', class: 'text-left', sortable: true },
        { label: 'Last Action', key: 'last_call_action', class: 'text-left', sortable: true },
        { label: 'Type', key: 'type', class: 'text-left', sortable: true },
        { label: 'Created By', key: 'user.name', class: 'text-left', sortable: true },
        { label: 'Created At', key: 'created_at', class: 'text-left', sortable: true },
        { label: 'Note', key: 'comment', class: 'text-left', sortable: true }
      ]
    }
  },
  methods: {
    addMerchant () {
      console.log('hello')
    }
  }
}
</script>
